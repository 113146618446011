<template>
  <div class="user-info">
      <div class="info-top flex">
          <div class="left flex">
              <img src="https://images.qingtv.com/bonwey-oa/d5db51aea24f2079fe41c2ceba54a04c.svg" alt="">
          </div>
          <div class="right flex">
              <div>
                  <p>轻官网轻官网轻官网轻官网轻官网轻官网轻官网轻官网</p>
              </div>
              <button>更换头像</button>
          </div>
      </div>
      <div class="info-center">
          <div>
              
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style lang='less' scoped>
.flex{
    display: flex;
    align-items: center;
}
.between{
    justify-content: space-between;
}
.user-info{
    .info-top{
        padding: .2rem;
        .left{
            width: .45rem;
            height: .45rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: .15rem;
            img{
                // width: 100%;
                height: 100%;
            }
        }
        .right{
            font-size: .14rem;
            p{
                width: 1.5rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            button{
                width: .9rem;
                height: .3rem;
                background: #F5F5F5;  
                border: 1px solid #ADB5BA;
                border-radius: 30px;
                margin-left:.1rem;
            }
        }
    }
}
</style>